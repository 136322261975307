

.carousel1-Slick-prev,
.carousel1-Slick-next{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    border: none;
    outline: none;

    width: 2rem!important;
    height: 2rem!important;
    background: #454444!important;
    color: #fff!important;
    border-radius: 50%!important;
    opacity: 0.7!important;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* display: none; */
}
.carousel1-Slick-prev{
    /* margin: 68px 0 0 62px; */
    left: 1vw;
    
}
.carousel1-Slick-next{
    /* margin: 68px 62px 0 0; */
    right: 1vw;
}
.iconLeft{
    color: #fff!important;
}
.iconRight{
    color: #fff!important;
}

@media (max-width: 960px) {
    .carousel1-Slick-prev,
    .carousel1-Slick-next{
        display: none;
    }
}
/* @media (max-width: 1000px) {
    .carousel1-Slick-prev{
        left: 10.5vw;
    }
    .carousel1-Slick-next{
        right: 9vw;
    }
} */

